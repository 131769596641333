@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500&display=swap");
@import "normalize";
@import "base/color";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, Arial, sans-serif;
  scrollbar-color: var(--fbp-other-outlined-border) var(--fbp-background-default);
  &::-webkit-scrollbar {
    width: 8px;
    height: 100%;
  }
  &::-webkit-scrollbar-track {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    background-color: var(--fbp-action-hover);
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    background-color: var(--fbp-other-outlined-border);
  }
}

#WebsiteBuilderModule-container,
#WebsiteBuilder-root {
  font-family: Poppins, Arial, sans-serif;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

//iconbutton
.MuiIconButton-root:focus {
  background-color: unset;
}

//styled scrollbar
.pb_scroll_bar {
  height: 100%;
  overflow-y: auto;
}
.pb_scroll_bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #112c4f;
  opacity: 1;
}
.pb_scroll_bar::-webkit-scrollbar {
  width: 6px;
}
.pb_scroll_bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5a5bd7;
}

.wb_xscroll_bar {
  height: 100%;
  overflow-x: auto;
}
.wb_xscroll_bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--dark_blue);
}

.wb_xscroll_bar-80::-webkit-scrollbar-track {
  margin-top: 30px;
}
.wb_xscroll_bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.wb_xscroll_bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.wb_xscroll_bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--purple);
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
